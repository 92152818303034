<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <jeepay-text-up :placeholder="'应用ID'" :msg="searchData.appId" v-model="searchData.appId" />
            <jeepay-text-up :placeholder="'应用名称'" :msg="searchData.appName" v-model="searchData.appName" />
            <jeepay-text-up :placeholder="'汇付商户号'" :msg="searchData.adapayMchNo" v-model="searchData.adapayMchNo" />
            <jeepay-text-up :placeholder="'汇付商户名称'" :msg="searchData.adapayMchName" v-model="searchData.adapayMchName" />
            <jeepay-text-up :placeholder="'汇付应用ID'" :msg="searchData.adapayAppId" v-model="searchData.adapayAppId" />
            <jeepay-text-up :placeholder="'汇付应用名称'" :msg="searchData.adapayAppName" v-model="searchData.adapayAppName" />
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchFunc" icon="search" :loading="btnLoading">查询</a-button>
              <a-button style="margin-left: 8px;" @click="reset" icon="reload">重置</a-button>
              <a-button type="primary" style="margin-left: 8px;" @click="addFunc" icon="plus">新增</a-button>
            </span>
          </div>
        </a-form>
      </div>
      <!-- 列表渲染 -->
      <JeepayTable
        ref="infoTable"
        :initData="true"
        :reqTableDataFunc="reqTableDataFunc"
        :tableColumns="tableColumns"
        :searchData="searchData"
        @btnLoadClose="btnLoading=false"
        :scrollX="1200"
        rowKey="appId"
      >
        <template slot="opSlot" slot-scope="{record}">
          <JeepayTableColumns>
            <a-button type="link" @click="editFunc(record)">修改</a-button>
            <a-button type="link" @click="delFunc(record.appId)">删除</a-button>
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>
    <!-- 新增 / 修改 页面组件  -->
    <AdapayAppMchAddOrEdit ref="infoAddOrEdit" :callbackFunc="searchFunc" />
  </page-header-wrapper>
</template>

<script>
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp'
import AdapayAppMchAddOrEdit from './AdapayAppMchAddOrEdit'
import { API_URL_ADAPAY_APP_MCH, req } from '@/api/manage'

const tableColumns = [
  { key: 'appId', dataIndex: 'appId', title: '应用ID', width: '150px' },
  { key: 'appName', dataIndex: 'appName', title: '应用名称', width: '150px' },
  { key: 'adapayMchNo', dataIndex: 'adapayMchNo', title: '汇付商户号', width: '150px' },
  { key: 'adapayMchName', dataIndex: 'adapayMchName', title: '汇付商户名称', width: '150px' },
  { key: 'adapayAppId', dataIndex: 'adapayAppId', title: '汇付应用ID', width: '150px' },
  { key: 'adapayAppName', dataIndex: 'adapayAppName', title: '汇付应用名称', width: '150px' },
  { key: 'remark', dataIndex: 'remark', title: '备注', width: '150px' },
  { key: 'op', title: '操作', width: '150px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
]

export default {
  name: 'AdapayAppMchApp',
  components: { JeepayTable, JeepayTableColumns, JeepayTextUp, AdapayAppMchAddOrEdit },
  data () {
    return {
      tableColumns: tableColumns,
      searchData: {},
      btnLoading: false
    }
  },
  methods: {
    reqTableDataFunc: (params) => {
      return req.list(API_URL_ADAPAY_APP_MCH, params)
    },

    searchFunc: function () {
      this.btnLoading = true
      this.$refs.infoTable.refTable(true)
    },

    addFunc () {
      this.$refs.infoAddOrEdit.show(true)
    },

    editFunc (row) {
      this.$refs.infoAddOrEdit.show(false, row)
    },

    delFunc (appId) {
      const that = this
      this.$infoBox.confirmDanger('确认删除？', '', () => {
        return req.delById(API_URL_ADAPAY_APP_MCH, appId).then(res => {
          that.$message.success('删除成功！')
          that.$refs.infoTable.refTable(true)
        })
      })
    },
    reset () {
      this.searchData = {}
      this.$nextTick(() => {
        this.$refs.infoTable.refTable(true)
      })
    }
  }
}
</script>

<style scoped lang="less">
.table-operator {
  margin-bottom: 18px;
}
</style>
