var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("iframe", {
      attrs: {
        scrolling: "no",
        width: "300",
        height: "400",
        frameBorder: "0",
        allowTransparency: "true",
        src: _vm.setSrc
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }